import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
 
  private temp_data = new BehaviorSubject<string>('');

  userName: any;

  constructor() {
    this.userName = localStorage.getItem('userName');   
  }

  setData(data: string): void {
    if (this.userName != "") {
      this.temp_data.next(data);
    }
  }

  getData(): BehaviorSubject<string> {
    return this.temp_data;
  }
}