<div class="p-20">
    <div class="col-sm-12">
        <div class="text-center">
            <img src="../../../../assets/images/session-timeout.png" style="max-width: 150px;margin-top: 30px;">
            <h2 class="font-xl">
                <strong>
                    <i class="fa fa-fw fa-exclamation-triangle fa-lg"></i> Session Expired
                </strong>
            </h2>
            <p>
                Your session has expired. Please <a class="cursor-pointer blue-color text-underline"
                    (click)="logout()">login</a> again
                to
                continue.
            </p>
        </div>
    </div>
</div>