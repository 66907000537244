import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private loadingTextSubject = new BehaviorSubject<string>('Loading...');
  loadingText$ = this.loadingTextSubject.asObservable();

  constructor(private spinner: NgxSpinnerService) { }

  public show() {
    this.spinner.show();
  }

  public hide() {
    this.spinner.hide();
  }

  public getSpinner(name: string) {
    this.spinner.getSpinner(name);
  }

  public setLoadingText(text: string) {
    this.loadingTextSubject.next(text);
  }
}