import { Component, OnInit } from "@angular/core";
import { APP_SETTINGS } from "./config/config";
import { SpinnerService } from "./core/services/spinner.service";
import { AuthService } from "./core/services/auth.service";
import { BroadcastService } from "@azure/msal-angular";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.css"],
})
export class AppComponent implements OnInit {
  title = "BayGrape";
  showLoader: boolean;
  theme: string;
  favIcon: HTMLLinkElement = document.querySelector("#favIcon");
  loadingText$ = this.spinnerService.loadingText$;
  content = "<button>button</button>";
  constructor(
    private spinnerService: SpinnerService,
    private broadcastService: BroadcastService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.favIcon.href = APP_SETTINGS.FAVICON;
    let authResponse = localStorage.getItem("access_token");
    if (authResponse) {
      if (!this.authService.online(authResponse)) {
        this.authService.logout();
        localStorage.clear();
      }
    }

    //For AD Login
    this.broadcastService.subscribe("msal:loginSuccess", (payload) => {
      localStorage.setItem("access_token", payload._token);
    });
  }
  handleInputChange(event: any) {}
  ngOnDestroy() {}
}
