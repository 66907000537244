<div class="container-block">
    <div class="wrapper">
        <div class="landing-page">
            <div style="text-align:center;" class="icon__download">
               <img src="assets/images/404-error-page-not-found.svg" alt="404-image">
            </div>
            <h1>404 - Not Found</h1>
            <p>The page you are looking for is not found.</p>           
        </div>
    </div>
</div>