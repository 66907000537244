<div class="container-block">
    <div class="wrapper">
        <div class="landing-page">
            <div style="text-align:center;" class="icon__download">
                <img src="assets/images/401-error-unauthorized.svg" alt="Unauthorized-image">
            </div>
            <h1>401 - Unauthorized</h1>
            <p> You do not have permission to access this resource.</p>
        </div>
    </div>
</div>