export enum ToastMessage {
  Success = "Your request has been successfully completed.",
  Failure = "We couldn’t process your request. Please try again.",
  Exception = "Oops! Something went wrong.",
  UnAuthorized = "You do not have permission to access this.",
  ValidationError = "There were errors with the information you provided.",
  TokenExpired = "Your session has expired. Please log in again.",
  DataNotFound = "No data found matching your request.",
  DataAlreadyExists = "The data you’re trying to add already exists.",
  InvalidFileFormat = "The file format is not supported.",
  NoFileUploaded = "Please upload a file to proceed.",
  NoRecords = "No records were found.",
  DataExpired = "This data has expired and is no longer available.",
  PartialSuccess = "Some items were processed successfully, but others failed.",
  CompletedSuccessfully = "Your request has been completed successfully.",
  NotAcceptable = "Your current and new passwords must be different.",
}

export enum ToastCostomMessage {
  Added = "Item added successfully!",
  Updated = "Item updated successfully!",
  Error = "Oops! Something went wrong. Please try again.",

  PasswordUpdate = "Your password has been updated successfully!",
  PasswordMatch = "Passwords must match.",

  NoteAdded = "Notes added successfully.",
  NoteRequired = "Please add your notes.",

  Delete = "Item deleted successfully.",
  AllFileRequire = "All fields are required.",
  Submitted = "Your submission was successful!",
  RecordSaved = "Record saved successfully.",
  MaxFile = "You can upload up to 10 files only.",
  FileDelete = "File deleted successfully.",

  EmailSent = "Email sent successfully.",
  InvalidEmail = "The email address you entered is invalid.",

  RequestCreated = "Request created successfully.",
  RequestProcessed = "Request processed successfully.",
  NotificationSent = "Notification sent.",
  FileSizeExceed = "File size exceeds the 20 MB limit.",
  FileTypeRestrict = "file type is restricted.",
  HtmlFileTypeRestrict = "Only HTML files are allowed!",
  FileTypeSelect = "Please select a file.",
  FileDownloadSuccess = "File downloaded successfully.",
  SubscriptionExpiry = "Your subscription has expired.",
  // Newly added
  paymentSuccess = "Payment completed successfully!",
  transactionFailed = "Transaction failed. Please try again.",
  verificationCodeSent = "Verification code has been sent successfully.",
  passwordReset = "Check your email to reset your password.",
  duplicate = "Duplicate entry created.",
  acceptTerms = "Please accept the terms and conditions to proceed.",
  accessDenied = "Access denied. Please check your permissions.",
  invalidFiles = "Invalid files selected. Please choose valid files.",
  validExpiryDate = "Please enter a valid expiry date.",
  templateRequired = "Notification template is required.",
  downloadFailed = "Download failed. Please try again.",
  mandatoryFileds = "All required fields must be filled in.",
  emailRequired = "Please enter an email address.",
  docGroupEmpty = "Document group data is required.",
  docGroupNameRequired = "Please enter a name for the document group.",
  docGroupNameLength = "Document group name must be at least 3 characters long.",
  docGroupNameInvalid = "Please enter a name for the document group.",

  docTypeEmpty = "Document type data is required.",
  docTypeNameRequired = "Please enter a name for the document type.",
  docTypeNameLength = "Document type name must be at least 3 characters long.",
  docTypeNameInvalid = "Please enter a valid document type name.",

  requesterTypeRequired = "Please select a requester type.",
  requesterNameLength = "Requester name must be at least 3 characters long.",
  requesterNameInvalid = "Please enter a valid requester name.",
  docsetNameLength = "Document set name must be at least 3 characters long.",
  docsetNameInvalid = "Please enter a valid document set name.",
  duplicateFieldName = "This field name already exists.",
  singleFileSelection = "Only one file can be selected.",
  informationRequired = "Please provide the required information.",
  selectDocumentSet = "Please select a document set.",
  enterDocumentSet = "Please enter document set name.",

  selectDocumentGroup = "Please select a document group.",
  allowedFileFormats = "Only JPG and PNG formats are allowed.",
  invalidImageUpload = "Please upload a valid image.",
  formOrDocsetRequired = "Please select either a form set, document set, or both.",
  requestReopened = "The request has been reopened successfully.",
  enterVerificationcode = "Enter the verification code sent to your email.",
  restrictedFiles = "Only PDF, IMAGE, DOC, DOCX, XLS, and XLSX file formats are allowed.",
}

export enum Docstatus {
  Pending = "Pending",
  Completed = "Completed",
  AdditionalInfoRequired = "Additional Info Required",
  Uploaded = "Uploaded",
}

export enum DocstatusMessage {
  ToPending = "Status changed to Pending successfully.",
  ToCompleted = "Status changed to Completed successfully.",
  ToAdditionalInfoRequired = "Status updated to 'Additional Info Required' successfully.",
  ToUploaded = "Status updated to 'Uploaded' successfully.",
}
export enum AdditionalInfoMessage {
  Note = "Please check the notes for additional information.",
}
