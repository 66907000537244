import { Component, OnInit } from '@angular/core';
import { NotesService } from 'src/app/core/services/notes.service';
@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.css']
})
export class LayoutComponent implements OnInit {

  isScrollable: boolean = false;

  constructor(private notesService: NotesService) {
  }

  ngOnInit() {
   this.notesService.showNotes.subscribe((res)=>{
    this.isScrollable = res;
   })
  }
}
