import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsRoutingModule } from './errors-routing.module';
import { NotfoundComponent } from './notfound/notfound.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ExpiredComponent } from './expired/expired.component';
import { SessionExpiryComponent } from './session-expiry/session-expiry.component';


@NgModule({
  declarations: [NotfoundComponent, UnauthorizedComponent, ExpiredComponent, SessionExpiryComponent],
  imports: [
    CommonModule,
    ErrorsRoutingModule
  ]
})
export class ErrorsModule { }
