import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_SETTINGS, CONFIG_CONSTANTS } from 'src/app/config/config';
import { ToastCostomMessage } from 'src/app/core/enum/toast-message.enum';
import { ToastTitle } from 'src/app/core/enum/toast-title.enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { HandleErrorService } from 'src/app/core/services/handle-error.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastMessageService } from 'src/app/core/services/toast-message.service';


@Component({
  selector: 'app-create-passowrd',
  templateUrl: './create-passowrd.component.html',
  styleUrls: ['./create-passowrd.component.css']
})
export class CreatePassowrdComponent implements OnInit {

  form: UntypedFormGroup;
  loading = false;
  logo:any;
  copyRightYear: string = '';
  poweredBy: string = '';
  viewNewPassword: boolean = false
  viewConfirmPassword: boolean = false;
  key: string = '' ;

  passwordRules: string[] = [
    'Password must contain at least 8 characters',
    'At least 1 uppercase character (A-Z)',
    'At least 1 lowercase character (a-z)',
    'At least 1 digit (0-9)',
    'At least 1 special character'
  ];

  constructor(
    private toastr: ToastMessageService,
    private spinner: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: HandleErrorService,    
  ) { }

  ngOnInit(): void {
    this.logo = APP_SETTINGS.TRANSPARENT_LOGO;
    this.copyRightYear = CONFIG_CONSTANTS.BG_COPYRIGHT_YEAR;
    this.poweredBy = CONFIG_CONSTANTS.BG_POWERED_BY;
    this.route.queryParams.subscribe((params) => {
      this.key = params.key;
    });
    this.setReactiveForm();
    //this.checkPasswordExpiry();
  }

  setReactiveForm() {
    this.form = this.formBuilder.group({
      newPassword: ['',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\\|[\]{};:'",.<>/?]).{8,}$/),
          Validators.minLength(8),
        ]
      ],
      confirmPassword: ['',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\\|[\]{};:'",.<>/?]).{8,}$/),
          Validators.minLength(8),
        ]
      ],
    });
  }

  public checkPasswordExpiry() {
    this.spinner.show();
    let key =  {
      key : this.key
    }
    this.authService.checkPasswordExpiry(key).subscribe((result) => {
      if (result && result.statuscode == 1) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.router.navigateByUrl("/error/expired");
      }
    });
  }

  public createPassword() {
    if(this.form.valid) {
      if (this.form.value.newPassword === this.form.value.confirmPassword) {
        this.spinner.show();
        let object = {
          key: this.key,
          newPassword: this.form.value.newPassword,
          confirmPassword: this.form.value.confirmPassword,
        }    
        this.authService.createPassWord(object).subscribe({
          next: (apiResponse) => {
            this.spinner.hide();
            if(apiResponse && apiResponse.statuscode != 1) {
              this.errorService.handleError(apiResponse);
            } else {
              this.form.reset();                     
              this.authService.logout();
              this.toastr.success(ToastCostomMessage.PasswordUpdate, ToastTitle.Success)
            }
          }, error: err => {
            this.spinner.hide();  
            this.toastr.error(ToastCostomMessage.Error, ToastTitle.Error)
          }    
        });          
      } else {        
        this.toastr.warning(ToastCostomMessage.PasswordMatch, ToastTitle.Warning)
      }
    } else {
      this.toastr.warning(ToastCostomMessage.FormRequired, ToastTitle.Warning);   
    }
  }

}
