import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "src/app/app.component";
import { AppRoutingModule } from "src/app/app.routing.module";
import { LayoutComponent } from "src/app/shared/layout/layout.component";
import { HeaderComponent } from "src/app/shared/layout/header/header.component";
import { FooterComponent } from "src/app/shared/layout/footer/footer.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppCommonModule } from "src/app/app.common.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ApiService } from "./core/services/api.service";
import { ServiceGateway } from "./core/services/servicegatewayNew.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorsModule } from "./shared/errors/errors.module";
import { CommonPipeModule } from "./core/pipes/common.pipes.module";
import { AppLayoutComponent } from "./shared/app-layout/app-layout.component";
import { AdminHeaderComponent } from "./shared/app-layout/admin-header/admin-header.component";
import { AdminFooterComponent } from "./shared/app-layout/admin-footer/admin-footer.component";
import { NgxPaginationModule } from "ngx-pagination";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { DatePipe } from "@angular/common";
import { MsalModule, MsalService } from "@azure/msal-angular";
import { MsalInterceptor } from "@azure/msal-angular";
import { JwtModule } from "@auth0/angular-jwt";
import { AuthGuard } from "./core/guards/auth.guard";
import { AuthService } from "./core/services/auth.service";
import { AuthComponent } from "./features/auth/auth.component";
import { SiteLayoutComponent } from "./shared/site-layout/site-layout.component";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { QuillModule } from "ngx-quill";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

export function loggerCallback(logLevel, message, piiEnabled) {
  console.log("client logging" + message);
}

export const protectedResourceMap: [string, string[]][] = [
  ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
];

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AuthComponent,
    SiteLayoutComponent,
    HeaderComponent,
    FooterComponent,
    AppLayoutComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
  ],
  imports: [
    QuillModule.forRoot(),
    PdfJsViewerModule,
    BrowserModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonPipeModule,
    FormsModule,
    NgxSpinnerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    AppCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MsalModule.forRoot({
      clientID: "e05cdd21-0b1a-4e4d-8c22-5c4d03ddb84d",
      authority:
        "https://login.microsoftonline.com/8a49dc45-81ea-45dc-ba0a-eb06c1264550/",
      validateAuthority: true,
      redirectUri: window.location.origin + "/admin/docservice/docrequest-list",
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
      consentScopes: ["user.read", "openid", "profile"],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap: protectedResourceMap,
      logger: loggerCallback,
      piiLoggingEnabled: true,
    }),
  ],
  exports: [TranslateModule, ErrorsModule, NgxPaginationModule],
  providers: [
    AuthGuard,
    AuthService,
    MsalService,
    ServiceGateway,
    ServiceGateway,
    ApiService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
