import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { API_URLS } from "src/app/config/config";
import { ServiceGateway } from "./servicegatewayNew.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { SessionService } from "./session.service";
import * as moment from "moment";
import jwt_decode from "jwt-decode";
import { SpinnerService } from "./spinner.service";
import { MsalService } from "@azure/msal-angular";
import { LoginTypes } from "../enum/login-type.enum";
import { Token } from "../models/token";
import { CONFIG_CONSTANTS } from "src/app/config/config";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private loggedUserSubject: BehaviorSubject<any>;
  public freeTrialUser: BehaviorSubject<string> = new BehaviorSubject("");
  public userData: BehaviorSubject<string> = new BehaviorSubject("");
  constructor(
    private serviceGateWay: ServiceGateway,
    private router: Router,
    public sessionService: SessionService,
    public spinner: SpinnerService,
    private msalService: MsalService
  ) {}

  public login(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.login,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          if (response?.statuscode == 1 && response?.data) {
            const token = response?.data?.token;
            if (token) {
              localStorage.setItem("access_token", token);
              const helper = new JwtHelperService();
              const data = helper.decodeToken(token);
              //configured for common object
              let tokenObj = {
                un: data?.un,
                em: data?.em,
              };
              localStorage.setItem("auth_info", JSON.stringify(tokenObj));

              if (data) {
                this.router.navigateByUrl("/docservice");
                this.loggedUserSubject?.next(data);
              }
            }
          } else if (response?.statuscode == 29 && response?.data) {
            if (response?.data) {
              this.sessionService.setItem(
                "FreeTrialUser",
                response?.data?.data?.data
              );
              this.router.navigateByUrl("/freeTrial/onboard-user-form");
            }
          } else if (response?.statuscode == 30 && response?.data) {
            if (response?.data) {
              this.sessionService.setItem(
                "FreeTrialUser",
                response?.data?.data?.data
              );
              this.router.navigateByUrl("/freeTrial/verification");
            }
          }
          return response;
        })
      );
  }

  public forgotPassWord(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.forgotPassword,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public createPassWord(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.validateForgotPassword,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("access_token");
    if (!token) {
      return false;
    }
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    if (isExpired) {
      return false;
    }
    return true;
  }
  public isRequestAuthenticated(): boolean {
    const token = localStorage.getItem("client_token");
    if (!token) {
      return false;
    }
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    if (isExpired) {
      return false;
    }
    return true;
  }
  logout() {
    if (API_URLS.Generic_Login_type === LoginTypes.USERNAME_PASSWORD) {
      localStorage.removeItem("access_token");
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigateByUrl("/login");
    } else {
      localStorage.removeItem("access_token");
      localStorage.clear();
      sessionStorage.clear();
      this.msalService.logout();
    }
  }

  public freeTrialRegistration(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.freeTrialRegister,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          this.freeTrialUser.next(response?.data);
          return response;
        })
      );
  }

  public checkPasswordExpiry(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.checkPasswordExpiry,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public checkverificationcode(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.checkverificationcode,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public userOnboard(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.onBoardUser,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public resendVerificationCode(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.resendVerificationCode,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public onboardedSignIn(data: any): Observable<any> {
    return this.serviceGateWay
      .post(
        API_URLS.Generic_Identity_api_url + API_URLS.onboardedSignin,
        API_URLS.ContentType,
        data
      )
      .pipe(
        map((response) => {
          if (response?.statuscode == 1 && response?.data) {
            const token = response?.data?.token;
            if (token) {
              localStorage.setItem("access_token", token);
              const helper = new JwtHelperService();
              const data = helper.decodeToken(token);
              if (data) {
                this.loggedUserSubject?.next(data);
              }
            }
          }
          return response;
        })
      );
  }

  public getTimeZone(): Observable<any> {
    return this.serviceGateWay
      .get(API_URLS.Generic_Identity_api_url + API_URLS.getTimeZone)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getExpirationTime(token: any) {
    var access_token: any = jwt_decode(token);
    if (access_token != undefined && access_token != null && access_token.exp) {
      return +access_token.exp;
    } else {
      return 0;
    }
  }

  online(session: any) {
    let json_session = session;
    let today = moment().utc().format("X");
    let currentTime = +today;
    if (json_session) {
      let expireTime = this.getExpirationTime(json_session);
      let sessionAndTokenValid: boolean =
        json_session && expireTime > currentTime;
      if (sessionAndTokenValid == null || sessionAndTokenValid == false)
        return false;
      else return sessionAndTokenValid;
    }
  }

  clearSessionWhenExpired() {
    localStorage.removeItem("access_token");
    this.clearStorage();
    this.spinner.hide();
    this.router.navigateByUrl("/login");
    // location.reload();
  }

  clearStorage() {
    localStorage.clear();
  }

  getTokenObjects(): any {
    let authResponse: any = this.getAuthResponse();
    if (this.online(authResponse)) {
      let json_data = authResponse;
      let decodedToken = jwt_decode(json_data);
      let tokenObject = this.decodedTokenObjectCreation(decodedToken);
      return tokenObject;
    }
  }

  getAuthResponse(): any {
    return localStorage.getItem("access_token");
  }

  decodedTokenObjectCreation(decodedToken) {
    let tokenObject = new Token();
    if (API_URLS.Generic_Login_type == LoginTypes.USERNAME_PASSWORD) {
      tokenObject.em = decodedToken?.em;
      tokenObject.un = decodedToken?.un;
      tokenObject.exp = decodedToken?.exp;
      tokenObject.tc = decodedToken?.tc;
    } else {
      tokenObject.em = decodedToken?.preferred_username;
      tokenObject.un = decodedToken?.un;
      tokenObject.exp = decodedToken?.exp;
      tokenObject.tc = decodedToken?.tc;
    }

    return tokenObject;
  }
}
