import { Component, OnInit } from '@angular/core';
import { CONFIG_CONSTANTS } from 'src/app/config/config';

@Component({
  selector: 'app-admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.css']
})
export class AdminFooterComponent implements OnInit {

  copyRightYear: string = '';
  poweredBy: string = '';

  constructor() { }

  ngOnInit(): void {
    this.copyRightYear = CONFIG_CONSTANTS.BG_COPYRIGHT_YEAR;
    this.poweredBy = CONFIG_CONSTANTS.BG_POWERED_BY;
  }

}
