import { Injectable } from '@angular/core';
import { ToastMessage } from '../enum/toast-message.enum';
import { ToastTitle } from '../enum/toast-title.enum';
import { ToastMessageService } from './toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {

  constructor(
    private toastr: ToastMessageService
  ) { }

  public handleError(error: any) {
    if(error != undefined) {
      if(error.message) {              
        this.toastr.error(error.message, ToastTitle.Error);
      } else {
        switch (error.statusCode) {
          case 1:
            this.toastr.success(ToastMessage.Success, ToastTitle.Success);
            break;
          case 2:
            this.toastr.error(ToastMessage.Failure, ToastTitle.Error);
            break;
          case 3:
            this.toastr.error(ToastMessage.Exception, ToastTitle.Error);
            break;
          case 4:
            this.toastr.error(ToastMessage.UnAuthorized, ToastTitle.Error);
            break;
          case 5:
            this.toastr.error(ToastMessage.ValidationError, ToastTitle.Error);
            break;
          case 6:
            this.toastr.error(ToastMessage.TokenExpired, ToastTitle.Error);
            break;
          case 7:
            this.toastr.info(ToastMessage.DataNotFound, ToastTitle.Info);
            break;
          case 8:
            this.toastr.info(ToastMessage.DataAlreadyExists, ToastTitle.Info);
            break;
          case 9:
            this.toastr.warning(ToastMessage.InvalidFileFormat, ToastTitle.Warning);
            break;
          case 10:
            this.toastr.error(ToastMessage.NoFileUploaded, ToastTitle.Error);
            break;
          case 11:
            this.toastr.info(ToastMessage.NoRecords, ToastTitle.Info);
            break;
          case 12:
            this.toastr.error(ToastMessage.DataExpired, ToastTitle.Error);
            break;
          case 13:
            this.toastr.warning(ToastMessage.PartialSuccess, ToastTitle.Warning);
            break;
          case 14:
            this.toastr.success(ToastMessage.CompletedSuccessfully, ToastTitle.Success);
            break;
          case 15:
            this.toastr.error(ToastMessage.NotAcceptable, ToastTitle.Error);
            break;
        }
      }    
    } else {
      this.toastr.error(ToastMessage.Exception, ToastTitle.Error);
    }
  }
}

