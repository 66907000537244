import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css']
})
export class HeaderComponent implements OnInit {
  logo: any;

  constructor() {
  }

  ngOnInit() {
    // this.logo = APP_SETTINGS.LOGO;
    // const logoRef: HTMLImageElement = document.querySelector('#client-logo'); 
    // logoRef.style.height = APP_SETTINGS?.LOGO_CLIENT_SIZE || '30px';
    
    // const logoLink:HTMLAnchorElement = document.querySelector('#logo-link');
    // logoLink.href = APP_SETTINGS?.LOGO_CLIENT_LINK;

    // if(APP_SETTINGS?.LOGO_CLIENT_LINK != '#' && APP_SETTINGS?.LOGO_CLIENT_LINK != '') {
    //   logoLink.target = '_blank';
    // }
  }
}
