import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { API_URLS } from 'src/app/config/config';
import { ServiceGateway } from './servicegatewayNew.service';



@Injectable({
    providedIn: 'root',
})
export class PermissionsService {

    constructor(private serviceGateWay :ServiceGateway ) {
    }

    public modulePermission(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_Identity_api_url + API_URLS.getPermByModCode, 'application/json', data).pipe(map(response => {
            return response;
          }));
      }

    public projectPermission(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_Identity_api_url + API_URLS.getPermissionsByProjCode , 'application/json', data).pipe(map(response => {
            return response;
          }));
      }

    public getModuleCode(slug: string):Observable<any>  {
        return this.serviceGateWay.get(API_URLS.Generic_Identity_api_url + API_URLS.permissions + slug).pipe(map(response => {
            return response;
          }));
      }

    public projectPermissionByProjName(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_Identity_api_url + API_URLS.getPermissionsByProjName , 'application/json', data).pipe(map(response => {
            return response;
          }));
      }
}