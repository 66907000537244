<div class="container-block">
    <div class="wrapper">
        <div class="landing-page">
            <div style="text-align:center;" class="icon__download">
                <img src="assets/images/no-data.svg" alt="Expired-Image">
            </div>
            <h1>Link Expired</h1>
            <p>Oops! This Url is not valid anymore.</p>
        </div>
    </div>
</div>