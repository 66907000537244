import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { sanitizeHtmlPipe } from 'src/app/core/pipes/sanitize-html.pipe';
import { TruncatePipe } from './truncate.pipe';
import { FormatTimePipe } from './format-time.pipe';
import { SafeHtmlPipe } from './safeHtmlpipe.pipe';

@NgModule({
  declarations: [
    sanitizeHtmlPipe,
    TruncatePipe,
    SafeHtmlPipe,
    FormatTimePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [sanitizeHtmlPipe, TruncatePipe, FormatTimePipe, SafeHtmlPipe]
})
export class CommonPipeModule { }
