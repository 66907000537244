import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS, CONFIG } from 'src/app/config/config';
import { ProfileDetails } from '../models/account';
import { ServiceGateway } from './servicegatewayNew.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private serviceGateWay: ServiceGateway) { }

  public getAccountDetails(emaiId: string): Observable<ProfileDetails> {
    return this.serviceGateWay.post( API_URLS.Generic_Identity_api_url + API_URLS.getAccountDetails_url + "/" + emaiId, CONFIG.ContentType,null);
  }

  public updateAccountDetails(data: ProfileDetails): Observable<ProfileDetails> {
    return this.serviceGateWay.post( API_URLS.Generic_Identity_api_url + API_URLS.updateAccountDetails_url, CONFIG.ContentType, data);
  }

  public updateAccountPassword(data: ProfileDetails): Observable<any> {
    return this.serviceGateWay.post(API_URLS.Generic_Identity_api_url + API_URLS.updateAccountPassword_url, CONFIG.ContentType, data);
  }
}
