<div class="site-register-wrapper">

    <header aria-label="banner">
        <div class="logo-container padding-y-2">

        </div>
    </header>
    <main class="site-wrap site-primary-lighter" id="main-content">
        <div id="register-form-section" class="padding-y-4 tablet:padding-4 card">
            <form>

                <h1 class="margin-0">Feedback Form</h1>
                <p> We would love to hear your thoughts, suggestions, concerns or problems with anything so that we can improve</p>
                <div class="form-group margin-top-2">
                <label class="usa-label text-bold" for="firstName">Feedback Type:</label>
                <div class="radio">
                      <input type="radio" id="html" name="fav_language" value="HTML">
                      <label for="html" class="radioLable">Comments</label><br>
                      <input type="radio" id="css" name="fav_language" value="CSS">
                      <label for="css" class="radioLable">Suggestions</label><br>
                      <input type="radio" id="javascript" name="fav_language" value="JavaScript">
                      <label for="javascript" class="radioLable">Question</label>
                </div>
                </div>
                <div class="form-group margin-top-2">
                    <label class="usa-label text-bold" for="firstName">Describe your feedback : </label>
                    <textarea class="field" rows="5" name="firstName" type="text"
                        formControlName="firstName" maxlength="300"> </textarea>
                </div>
                <div class="form-group margin-top-2">
                    <label class="usa-label text-bold" for="firstName"> Name</label>
                    <input class="usa-input field" id="firstName" name="firstName" type="text"
                        formControlName="firstName" autocorrect="off" maxlength="256" />
                </div>
                <div class="form-group margin-top-2">
                    <label class="usa-label text-bold" for="emailId">Email address</label>
                    <input class="usa-input field" id="emailId" name="emailId" type="email" formControlName="emailId"
                        autocorrect="off" />
                </div>

                <input type="submit" value="Submit" class="usa-button  margin-top-2" />
            </form>

        </div>

    </main>

</div>