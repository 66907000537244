import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "../services/auth.service";
import { UserInfoService } from "../services/user-info.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private UserInfoService: UserInfoService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem("access_token");
    if (token) {
      if (!this.authService.isAuthenticated()) {
        this.router.navigate(["docservice/session-expired"]);
        return false;
      } else {
        // if(this.UserInfoService.isForcedPassword()) {
        //   this.router.navigateByUrl('/createpassword');
        // }
        return true;
      }
    } else {
      this.router.navigateByUrl("/login");
    }
  }
}
